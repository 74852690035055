import React from 'react'
import Helmet from 'react-helmet'

import Layout from "../../components/layout"
import BgImage from '../../components/bg-image'

const Decking = () => (
  <Layout>
    <Helmet title="Pool" />
    <BgImage image="/assets/pool-surrounds.jpg" className="top">
      <h1>Decking</h1>
    </BgImage>
    <section className="content">
      <p>
        It’s important to have a good pool deck. We can install many types of
        decking for your pool that is natural, clean, modern and safe. Depending
        on your choice of materials, you can create the perfect extension to
        home’s exterior, or something unique and glamorous, just outside your
        back door. We have the following options available:
      </p>
      <ul>
        <li>Wooden</li>
        <li>Modwood</li>
        <li>Aluminum Decking</li>
        <li>Composite Decking</li>
      </ul>
    </section>
  </Layout>
)

export default Decking
